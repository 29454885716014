import React from "react";

import css from './ListingPage.module.css';


const SectionBrandMaybe = props => {
    const {publicData, brand } = props;
    if (!publicData) {
      return null;
    }
  
    return (
      <div className={css.sectionFeatures1}>
        <h2 className={css.featuresTitle}>
         Brand
        </h2>  
        {brand}
      </div>
    );
  };
  
  export default SectionBrandMaybe;